import React from "react";
import {
  HOMEPAGE_MAIN_DESC,
  HOMEPAGE_MAIN_TITLE,
  MAIN_BANNER,
  MAIN_BANNER_DESC_COLOR,
  MAIN_BANNER_DESC_COLOR_MOBILE,
  MAIN_BANNER_TITLE_COLOR,
  MAIN_BANNER_TITLE_COLOR_MOBILE,
  MAIN_BG_COLOR,
} from "../utils/constants";

const Home = () => {
  return (
    <>
      <div
        className="container pb-md-5 px-0"
        style={{ overflow: "hidden", marginBottom: "100px" }}
      >
        <div className="row">
          <img
            src={MAIN_BANNER}
            alt="banner_img"
            width="100vw"
            className="d-none d-lg-block"
            style={{ objectFit: "cover" }}
            loading="lazy"
          />

          <div
            className="d-none d-lg-block position-absolute p-5 top-50 rounded-pill"
            style={{ width: "38%", backgroundColor: MAIN_BG_COLOR }}
          >
            <h1
              className="fw-bold animated animatedFadeInUp fadeInUp animation-delay"
              style={{ fontSize: "50px", color: MAIN_BANNER_TITLE_COLOR }}
            >
              {HOMEPAGE_MAIN_TITLE}
            </h1>
            <p
              className="animated animatedFadeInUp fadeInUp animation-delay2"
              style={{ color: MAIN_BANNER_DESC_COLOR }}
            >
              {HOMEPAGE_MAIN_DESC}
            </p>
          </div>

          {/* Mobile Devices */}
          <img
            src={MAIN_BANNER}
            alt="banner_img"
            width="100vw"
            height="50%"
            className="d-block d-lg-none"
            style={{ objectFit: "cover" }}
            loading="lazy"
          />
          <div
            className="d-block d-lg-none position-absolute text-dark p-5 w-75"
            style={{ top: "25%" }}
          >
            <h1
              className="fw-bold animated animatedFadeInUp fadeInUp animation-delay"
              style={{ fontSize: "25px", color: MAIN_BANNER_TITLE_COLOR_MOBILE }}
            >
              {HOMEPAGE_MAIN_TITLE}
            </h1>
            <p
              className="animated animatedFadeInUp fadeInUp animation-delay2 fw-bold"
              style={{ fontSize: "10px", color: MAIN_BANNER_DESC_COLOR_MOBILE }}
            >
              {HOMEPAGE_MAIN_DESC}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
