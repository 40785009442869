import React from "react";
import {
  SERVICES_BANNER_TWO,
  SERVICES_SECTION_BG_COLOR,
  SERVICES_SECTION_DESC_COLOR,
  SERVICES_SECTION_NUMBER_COLOR,
  SERVICES_SECTION_STRIP_COLOR,
  SERVICES_SECTION_TITLE_COLOR,
  servicesData,
} from "../utils/constants";

const SectionTwo = () => {
  return (
    <>
      <div
        className="container pb-2 pb-md-4 position-relative shadow-lg"
        id="marketing"
        style={{
          marginBottom: "100px",
          backgroundColor: SERVICES_SECTION_BG_COLOR,
        }}
      >
        <div className="row" style={{ height: "100vh" }}>
          {/* Mobile responsive */}
          <div
            className="col-12 col-md-3 p-md-0 d-md-none d-block animated animatedFadeInUp fadeInUp animation-delay5"
            style={{ backgroundColor: SERVICES_SECTION_STRIP_COLOR }}
          >
            <h1
              className="fw-bolder ps-2"
              style={{ fontSize: "50px", color: SERVICES_SECTION_NUMBER_COLOR }}
            >
              2
            </h1>
          </div>
          <div className="row d-block d-md-none d-flex justify-content-start animated animatedFadeInRight fadeInRight animation-delay6">
            <h1
              className="fw-bolder"
              style={{ fontSize: "50px", color: SERVICES_SECTION_TITLE_COLOR }}
            >
              Marketing
            </h1>
          </div>

          <div className="col-12 col-md-9 p-md-0">
            <div className="row pe-md-5 pt-2 pt-md-0 animated animatedFadeInLeft fadeInLeft animation-delay5">
              <img
                src={SERVICES_BANNER_TWO}
                alt="section-2"
                width="100%"
                height="280"
                style={{ objectFit: "cover" }}
                loading="lazy"
                className="d-none d-md-block"
              />
              {/* Mobile responsive */}
              <img
                src={SERVICES_BANNER_TWO}
                alt="section-2"
                width="100%"
                height="180"
                style={{ objectFit: "cover" }}
                loading="lazy"
                className="d-block d-md-none"
              />
            </div>

            <div className="row p-4 px-md-5 py-md-3 animated animatedFadeInRight fadeInRight animation-delay5">
              <div
                className="px-0 px-md-4 border-bottom"
                style={{ color: SERVICES_SECTION_DESC_COLOR }}
              >
                <ul>
                  {servicesData["Marketing"].map((item, index) => (
                    <li key={index} style={{ listStyleType: "circle" }}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="row d-none d-md-block d-flex justify-content-start position-absolute animated animatedFadeInRight fadeInRight animation-delay6"
              style={{ top: "75%", right: "10%", zIndex: 1 }}
            >
              <h1
                className="fw-bolder"
                style={{
                  fontSize: "120px",
                  color: SERVICES_SECTION_TITLE_COLOR,
                }}
              >
                Marketing
              </h1>
            </div>
          </div>

          <div
            className="col-12 col-md-3 p-md-0 d-none d-md-block animated animatedFadeInUp fadeInUp animation-delay5"
            style={{ backgroundColor: SERVICES_SECTION_STRIP_COLOR }}
          >
            <h1
              className="fw-bolder ps-2"
              style={{
                fontSize: "120px",
                color: SERVICES_SECTION_NUMBER_COLOR,
              }}
            >
              2
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
