// import logo from './logo.svg';
import "./App.css";
import Home from "./Components/Home";
import Header from "./Components/Header";
import About from "./Components/About";
import SectionOne from "./Components/SectionOne";
import SectionTwo from "./Components/SectionTwo";
import SectionThree from "./Components/SectionThree";
import SectionFour from "./Components/SectionFour";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { MAIN_BG_COLOR } from "./utils/constants";

function App() {
  return (
    <div className="App" style={{ overflowX: "hidden", backgroundColor: MAIN_BG_COLOR }}>
      <Header />
      <Home />
      <About />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
