import React from "react";
import { LOGO, NAV_LINKS_COLOR } from "../utils/constants";

const Header = () => {
  return (
    <>
      <div className="container py-2 py-md-4">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
          <a
            href="/"
            className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
          >
            <img src={LOGO} alt="company-logo" width="200" height="auto" />
          </a>

          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <small>
              <li>
                <a href="#it-services" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  IT SERVICES
                </a>
              </li>
            </small>
            <small>
              <li>
                <a href="#marketing" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  MARKETING
                </a>
              </li>
            </small>
            <small>
              <li>
                <a href="#management" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  MANAGEMENT
                </a>
              </li>
            </small>
            <small>
              <li>
                <a href="#operations" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  OPERATIONS
                </a>
              </li>
            </small>
            <small>
              <li>
                <a href="#about" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  ABOUT
                </a>
              </li>
            </small>
            <small>
              <li>
                <a href="#contact" className="nav-link px-2" style={{ color: NAV_LINKS_COLOR }}>
                  CONTACT
                </a>
              </li>
            </small>
          </ul>
        </header>
      </div>
    </>
  );
};

export default Header;
