export const LOGO = process.env.PUBLIC_URL + "/images/logo.png";
export const FAVICON = process.env.PUBLIC_URL + "/images/favicon.png";

//images
export const MAIN_BANNER =
  process.env.PUBLIC_URL + "/images/homepage_banner.avif";
export const SERVICES_BANNER_ONE =
  process.env.PUBLIC_URL + "/images/information_tech.avif";
export const SERVICES_BANNER_TWO =
  process.env.PUBLIC_URL + "/images/marketing.avif";
export const SERVICES_BANNER_THREE =
  process.env.PUBLIC_URL + "/images/management.avif";;
export const SERVICES_BANNER_FOUR =
  process.env.PUBLIC_URL + "/images/operations.avif";

//contact details  
export const MAPS_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2612.576068656922!2d55.327537563689525!3d25.26949549890735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d3c9c8604bb%3A0x4745751b8218401e!2sWARBA%20CENTER!5e0!3m2!1sen!2sin!4v1711561776986!5m2!1sen!2sin";

export const CONTACT_NUMBER = "+971 50 294 8751";
export const EMAIL_ID = "jmsolutionsdubai@gmail.com";
export const TIMING = "09:00 AM - 06:00 PM (Mon-Sat)";
export const ADDRESS = "M35-316, Warba Center, Al-Muraqqabat, Dubai - UAE";

//social media links & footer content
export const FACEBOOK_URL = "#!";
export const INSTA_URL = "https://www.instagram.com/jmastersolutions?igsh=M3VncWRhcW1mNHdx&utm_source_qr";
export const LINKEDIN_URL = "#!";

export const FOOTER_COMPANY_NAME = new Date().getFullYear() + " J Master Solutions LLC";

//page content
export const HOMEPAGE_MAIN_TITLE = "Empowering Innovation...";
export const HOMEPAGE_MAIN_DESC = "Unleashing Your Business Potential Through Strategic Innovation and Dynamic Consulting.";
export const ABOUT_SECTION_CONTENT =
  "Welcome to our platform, your premier destination for innovative solutions tailored to meet your business needs. We specialize in providing cutting-edge services across information technology, marketing, tailored consulting and business operations. Our company is dedicated to delivering exceptional results through strategic thinking and personalized approaches. Whether you're seeking IT solutions, marketing strategies, specialized consulting and operational services, we've got you covered. With a focus on innovation, efficiency, and client satisfaction, we strive to exceed expectations and drive success for your business. Experience the difference with us today.";

//services data
export const servicesData = {
  "IT Services": [
    "Computer disaster recovery",
    "Installation (setting-up) of personal computers",
    "Software installation",
    "IT security management",
    "Cyber security consultancy",
    "Security systems consultancy",
    "Cyber risk management services",
    "Innovation and artificial intelligence research and consultancies",
  ],
  "Marketing": [
    "Marketing, public relations & communication consultancy",
    "Lobbying activities",
    "Digital content management",
    "Project management services",
    "Public relation services",
  ],
  "Management": [
    "Advice and help to businesses and public services in planning, organization, efficiency and control, management information etc.",
    "Strategy advisory services",
    "Immigration consultancy",
    "Hospitality management consultancy",
    "Health management consulting services",
    "Quality, standardization and measuring consultancy",
  ],
  "Operations": [
    "Wholesale of a variety of goods without any particular specialization",
    "Design of accounting methods or procedures, cost accounting programmes, budgetary control procedures",
    "Procurement consulting",
    "Logistics consultancy",
    "Cargo freight consultancy",
  ],
};

//theme color codes
export const MAIN_BG_COLOR = "#121e1b";
export const NAV_LINKS_COLOR = "#cdb688";

export const MAIN_BANNER_TITLE_COLOR = "#cdb688";
export const MAIN_BANNER_DESC_COLOR = "#d6d2c8";
export const MAIN_BANNER_TITLE_COLOR_MOBILE = "#cdb688";
export const MAIN_BANNER_DESC_COLOR_MOBILE = "#121e1b";

export const ABOUT_SECTION_BG_COLOR = "#121e1b";
export const ABOUT_SECTION_TITLE_COLOR = "#cdb688";
export const ABOUT_SECTION_DESC_COLOR = "#d6d2c8";

export const SERVICES_SECTION_STRIP_COLOR = "#112c26";
export const SERVICES_SECTION_BG_COLOR = "#121e1b";
export const SERVICES_SECTION_NUMBER_COLOR = "#cdb688";
export const SERVICES_SECTION_TITLE_COLOR = "#cdb688";
export const SERVICES_SECTION_DESC_COLOR = "#d6d2c8";

export const CONTACT_SECTION_TITLE_COLOR = "#cdb688";
export const CONTACT_SECTION_CONTENT_COLOR = "#d6d2c8";
export const CONTACT_SECTION_BG_COLOR = "linear-gradient(to top, #121e1b, #112c26)";
