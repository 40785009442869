import React from "react";
import { ABOUT_SECTION_BG_COLOR, ABOUT_SECTION_CONTENT, ABOUT_SECTION_DESC_COLOR, ABOUT_SECTION_TITLE_COLOR } from "../utils/constants";

const About = () => {
  return (
    <>
      <div
        className="container pb-2 pb-md-4 position-relative"
        id="about"
        style={{ marginBottom: "100px" }}
      >
        <div className="row text-center" style={{ height: "100vh" }}>
          <div
            className="col-12 col-md-6 p-5 d-flex border animated animatedFadeInLeft fadeInLeft animation-delay5"
            style={{
              alignItems: "center",
              color: ABOUT_SECTION_DESC_COLOR,
              backgroundColor: ABOUT_SECTION_BG_COLOR,
            }}
          >
            {ABOUT_SECTION_CONTENT}
          </div>
          <div
            className="col-12 col-md-6 d-none d-md-block text-center"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1556085049-95f628203523?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat"
            }}
          >
            <h1
              className="fw-bolder d-none d-md-block animated animatedFadeInUp fadeInUp animation-delay5"
              style={{ fontSize: "120px", color: ABOUT_SECTION_TITLE_COLOR }}
            >
              About
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
